@media all and (max-width:990px) and (min-width:320px) {

}
section#slider{

}
.showOnRwd{
	display:none;
}
.showOnDesktop{
	display:block;
}
.hideOnRwd{
	display:block;
}

.hideOnDesktop{
	display:none !important;
}


/*	ZMIANA 	*/
.img_poster{
	background:url('../img/bg-app.png')no-repeat;
	width: 100%;
	height: 350px;
	background-size: contain;
	position: relative;
	.link_list{
		position: absolute;
		width: 200px;
		height: 170px;
		bottom: 10px;
		text-align: center;
		a{
			img{
				height: auto;
				width: 116px;
				margin-bottom: 5px;
			}
		}
	}
}
.ResizeFontPlus,
.ResizeFontMinus,
.changeContrastButton{
	background-color: #f1f6fa;
    border-color: #f1f6fa;
	padding: 2px 5px;
	border-radius:0px;
	color:#000;
}
.changeContrastButton{
	background: #fff url('../img/contrast-icon.png')no-repeat;
	width: 27px;
	height: 27px;
	background-size: 72%;
	background-position-x: 4px;
	background-position-y: 4px;
}

body{
	background:#fff;

	section#downloadAppLinks{
		background:#000;
		padding-top:10px;
		p{
			color:#fff;
			span{
				margin-right: 25px;
			}
			a {
				margin-right: 25px;
				@media (max-width: 767px){
					display: block;
					margin-top: 10px;
				}
				img {
					width: 100px;
					@media (max-width: 767px){
						width: 150px;
					}
				}
			}
		}
	}
	&.contrast{
		background:#000;

		.headline{
			color:#FFF832;
			font-weight: 700;
			font-size:28px;
			margin:40px 0;

			@media (max-width: 767px){
				margin: 25px 0;
			}
		}

		section#main{
			#cols{
				.leftCols{
					background:#000;
					border:1px solid #FFF832;
					.head {
						color: #FFF832;
						font-size: 18px;
						font-weight: 600;
						text-transform: uppercase;
						margin: 0 0 0px 0;
					}

					.box {
						margin-top: 20px;
						margin-bottom: 25px;

						&:last-of-type {
							margin-bottom: 0;
						}

						div.checkbox {
							margin-top: 10px;
							label {
								font-size: 16px;
								span{
									&:before{

										border: 1px solid #FFF832;
									}
									color: #FFF832;
									font-weight: 600;
								}
							}

							input[type="checkbox"]:checked + span:after{
								content: "\f00c";
								display: inline-block;
								font: normal normal normal 11px/1 FontAwesome;
								font-size: inherit;
								text-rendering: auto;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								position:absolute;
								left:0px;
								top:-2px;

							}
						}
						span.titleOnStars{
							font-size: 14px;
							text-transform: uppercase;
							font-weight: 600;
							color:#FFF832;
						}
						.submitFiltrs{
							border: 2px solid #FFF832;
							min-width: 100%;
							font-size: 16px;
							color: #FFF832;
							font-weight: 700;
						}
					}


				}
				#routeList {
					.route {
						border: 1px solid #FFF832;
						.more{
							display:none !important;
						}
						.rightText{
							background:#000;
							h5{
								color:#FFF832;
								a{
									color:#FFF832 !important;
								}
							}
							.list_details {
								.item {
									.title{
										font-size: 17px;
										color:#FFF832;
									}
									.text{
										font-weight: 600;
										font-size: 16px;
										color:#FFF832;
									}
								}
							}
						}
					}
				}
			}
			&.offer{

				#cols {
					.rightCols {
						&#offerCenter {
							h2 {
								color: #FFF832;
							}
							h3 {
								color: #FFF832;
								font-size: 25px;
							}
							p.desc {
								color: #FFF832;
								font-weight: 400;
								font-size: 20px;
							}
							.list_details{
								.item{
									.title{
										font-size: 16px;
										color: #FFF832;
									}
									.text{
										font-size: 16px;
										color: #FFF832;
									}
								}
							}
							.listAttributes{
								.item{
									color: #FFF832;
									font-size: 15px;
								}
							}
							ul{
								&.listText{
									li{
										font-size: 15px;
										color: #FFF832;
									}
								}
							}
						}
					}

				}



			}
		}
		section#newsletter {
			.headline{
				color:#000 !important;
			}
			button.submitNewsletter{
				border: 2px solid #000;
				font-size: 16px;
				color: #000;
				font-weight: 700;
			}
			input.inputEmail{
				color: #000;
				font-size: 19px;
				font-weight: 600;
			}
		}
		section#footer {
			.realizacja{
				color: #FFF832;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}

}

section#main{
	// padding-top:15px;
	position: relative;
	overflow:hidden;
	&.offer{
		margin-top:40px;

		#cols{
			.leftCols{
				&.offer{

					a.back{
						color:#005e20;
						font-size:12px;
						font-weight:600;
						text-transform: uppercase;
						content: '';
						position:relative;
						&:before{
							content: ' ';
							background: url('../img/next.png')no-repeat;
							position: absolute;
							width:25px;
							height:21px;
						}
						span{
							padding-left:40px;
						}
					}
					.img{
						margin-top: 30px;
						margin-bottom: 30px;
					}
					h5{
						font-size:16px;
						color:#000;
						font-weight: 600;
						text-transform: uppercase;
						margin-bottom: 15px;
					}

				}

			}
			.rightCols{
				width: 100%;

				&#offerCenter{
					#map{
						height:500px;
						width:100%;
						border:1px solid #a2a3a3;
					}
					.list_details{
						margin-top:40px;
						.item{
							width:100%;
							clear:both;
							margin-bottom: 8px;
							.title{
								padding-left: 0px;
								font-weight: 600;
								text-transform: uppercase;
								width: 250px;
								float: left;
								font-size: 14px;

								@media all and (max-width:990px) and (min-width:320px) {
									width: 100%;
								}
							}
							.text{
								font-weight: 400;
								text-transform: uppercase;
								color:#717374;
								border:none;
							}
						}
					}
					.listAttributes{
						margin-top:25px;
						color:#000000;
						font-size:12px;
						text-transform: uppercase;
						font-weight: 400;
						.item{
							margin-bottom: 35px;
							img{
								margin-right:30px;
								width: 25px;
							}
						}
					}
					h2{
						font-size:26px;
						color:#000;
						font-weight: 600;
						text-transform: uppercase;
					}
					h3{
						font-size:22px;
						color:#000;
						font-weight: 600;
						text-transform: uppercase;
						font-family: 'Signika', sans-serif;

						&.noclegi{
							content: '';
							padding-left: 40px;
							position: relative;
							padding-top: 4px;
							font-size: 19px;
							font-weight: 500;
							&:before{
								content: ' ';
								background:url('../img/placeholder_yelow.png')no-repeat;
								width:23px;
								height:33px;
								position: absolute;
								top:0;
								left:0;
							}
						}
						&.atrakcje{
							content: '';
							color:#80cbc4;
							padding-left: 40px;
							position: relative;
							padding-top: 4px;
							font-size: 19px;
							font-weight: 500;
							&:before{
								content: ' ';
								background:url('../img/placeholder_blue.png')no-repeat;
								width:23px;
								height:33px;
								position: absolute;
								top:0;
								left:0;
							}
						}
						&.gastronomia{
							content: '';
							color:#e57373;
							padding-left: 40px;
							position: relative;
							padding-top: 4px;
							font-size: 19px;
							font-weight: 500;
							&:before{
								content: ' ';
								background:url('../img/placeholder_red.png')no-repeat;
								width:26px;
								height:33px;
								position: absolute;
								top:0;
								left:0;
							}
						}
						&.parkingi{
							content: '';
							color:#9575cd;
							padding-left: 40px;
							position: relative;
							padding-top: 4px;
							font-size: 19px;
							font-weight: 500;
							&:before{
								content: ' ';
								background:url('../img/placeholder_pur.png')no-repeat;
								width:23px;
								height:33px;
								position: absolute;
								top:0;
								left:0;
							}
						}
						&.inne{
							content: '';
							color:#7cb342;
							padding-left: 40px;
							position: relative;
							padding-top: 4px;
							font-size: 19px;
							font-weight: 500;
							&:before{
								content: ' ';
								background:url('../img/placeholder_green.png')no-repeat;
								width:23px;
								height:33px;
								position: absolute;
								top:0;
								left:0;
							}
						}

					}
					ul.listText{
						color: #717374;
						font-size: 12px;
						font-weight: 400;
						text-transform: uppercase;
						margin-top: 35px;
						li{
							margin-bottom:10px;
						}
					}
					.icons{
						margin-top:20px;
						margin-bottom:15px;
						img{
							float:left;
							margin-right:12px;
							width:25px;
						}
					}
					p.desc{
						margin-top: 20px;
						color: #717374;
						font-weight: 400;
						font-size: 15px;
						line-height: 24px;
					}
					.gallery{
						.item{
							width: calc(20% - 10px);
							float: left;
							margin-right: 10px;
							margin-top: 10px;


							@media all and (max-width:990px) and (min-width:320px) {

								width: calc(50% - 10px);
							}


						}
					}


					table.odcinkiSzlaku{
						border:1px solid #dfdfe0;
						thead{
							background:#f1f6fa;
							tr{
								th{
									font-size:11px;
									font-weight: 600;
									color:#717374;
									border-right:1px solid #dfdfe0;
									text-align:center;
									&:last-child{
										border-right:none;
									}
								}
							}
						}
						tbody{
							tr{
								td{
									border-right:1px solid #dfdfe0;
									border-bottom:1px solid #dfdfe0;
									background: #ffffff;
									font-size:11px;
									font-weight: 400;
									color:#717374;
									text-align: center;
									&:last-child{
										border-right:none;
									}
									span.no{
										width:16px;
										height:16px;
										background: url('../img/no_icon.png')no-repeat;
										display: block;
										margin: 0 auto;
									}
									span.yes{
										width:16px;
										height:16px;
										background: url('../img/yes_icon.png')no-repeat;
										display: block;
										margin: 0 auto;
									}
								}
							}
						}
					}

					table.noclegi{
						border:1px solid #dfdfe0;
						margin: 20px 0px;
						thead{
							background:#ffd600;
							tr{
								th{
									font-size:10px;
									font-weight: 600;
									color:#717374;
									border-right:1px solid #dfdfe0;
									&:last-child{
										border-right:none;
									}
									text-align: center;
								}
							}
						}
						tbody{
							tr{
								td{
									border-right:1px solid #dfdfe0;
									border-bottom:1px solid #dfdfe0;
									background: #ffffff;
									font-size:11px;
									font-weight: 400;
									color:#717374;
									text-align: center;
									&:last-child{
										border-right:none;
									}
									span.no{
										width:16px;
										height:16px;
										background: url('../img/no_icon.png')no-repeat;
										display: block;
										margin: 0 auto;
									}
									span.yes{
										width:16px;
										height:16px;
										background: url('../img/yes_icon.png')no-repeat;
										display: block;
										margin: 0 auto;
									}
								}
							}
						}
					}
				}

			}
		}



	}

	&:before{
		content: ' ';
		background: url('../img/bg_left.png')no-repeat;
		background-size:80%;
		width:174px;
		height:2157px;
		position: absolute;
		top:0;
		left:0;
		pointer-events:none;
		z-index: -1;
		@media all and (max-width:990px) and (min-width:320px) {
			display:none;
		}
	}
	&:after{
		content: ' ';
		background: url('../img/bg_right.png')no-repeat;
		background-size: 81%;
		width: 224px;
		height: 2157px;
		position: absolute;
		top: 0;
		right: 0;
		background-position: 50px 0px;
		pointer-events:none;
		z-index: -1;
	}

	#cols{
		display:flex;
		@media (max-width: 1024px) {
			display:block;
		}
		// margin-top: 20px;
		margin-left:-7.5px;
		margin-right: -7.5px;

		> div{
			padding-left:7.5px;
			padding-right:7.5px;
			display: flex;

			@media (max-width: 1024px) {
				display:block;
			}
		}


		.leftCols {
			background: #f1f6fa;
			padding: 20px 15px;
			width: 100%;

			.head {
				color: #005e20;
				font-size: 16px;
				font-weight: 600;
				text-transform: uppercase;
				margin: 0 0 0px 0;
			}
			.box {
				margin-top: 20px;
				margin-bottom: 25px;

				&:last-of-type{
					margin-bottom: 0;
				}

				div.checkbox {
					margin-top: 10px;
					label {
						font-size: 11px;
						text-transform: uppercase;
						font-weight: 400;
						padding:0;
						position:relative;
						min-height: auto;

						input[type="checkbox"]{
						    position: absolute;
						    clip: rect(0,0,0,0);
						    pointer-events: none;
						}

						span{
							padding-left:22px;

							&:before{
								content: '';
								width: 12px;
								height: 12px;
								border:1px solid #717374;
								background:none;
								position:absolute;
								top:1px;
								left:0;
							}
						}

						input[type="checkbox"]:checked + span:after{
						    content: "\f00c";
					        display: inline-block;
						    font: normal normal normal 11px/1 FontAwesome;
						    font-size: inherit;
						    text-rendering: auto;
						    -webkit-font-smoothing: antialiased;
						    -moz-osx-font-smoothing: grayscale;
						    position:absolute;
						    left:1px;
						    top:2px;
							
						}
					}
				}
				span.titleOnStars{
					font-size: 11px;
					text-transform: uppercase;
					font-weight: 400;
				}
				.submitFiltrs{
					border:1px solid #898989;
					min-width:140px;
					width:auto;
					font-size:14px;
					color:#898989;
					text-align: center;
					text-transform: uppercase;
					padding-top: 11px;
					padding-bottom: 11px;
					background:transparent;
					transition: background 0.15s ease-in-out, color 0.15s ease-in-out;

					&:hover,
					&:focus{
						background: #898989;
						color:#fff;
					}
				}
			}
		}

		.rightCols{
			width: 100%;
		}

		#routeList{

			.route{
				margin-bottom: 15px;
				display:flex;
				position: relative;

				@media all and (max-width:767px) {
					display:block;
				}

				.more{
					position: absolute;
					background: url('../img/blue-arrow.png')no-repeat;
					width:11px;
					height:23px;
					top: calc(50% - 11.5px);
					right:20px;

					@media (max-width: 1199px){
						right: 10px;
					}
				}

				&:last-child{
					margin-bottom:0px;
				}

				.leftImg{
					width:255px;
					display: flex;
					overflow: hidden;

					@media all and (max-width:767px) {
						width: 100%;
						height: 150px;
					}

					a{
						background-size: cover !important;
						background-position: center center !important;
						cursor: pointer;
						display: block;
						width: 100%;
						transition: transform 0.5s ease-in-out;

						&:hover{
						    transform: scale(1.05);
						}
					}
				}
				.rightText{
					padding:20px 40px 20px 20px;
					background:#f1f6fa;
					width: calc(100% - 255px);
					position:relative;

					@media (max-width: 991px){
						padding-right:30px;
					}

					@media all and (max-width:767px)  {
						width:100%;
						padding:15px 30px 15px 15px;
					}

					h5{
						color: #000000;
						font-weight: 600;
						font-size: 22px;
						text-transform: uppercase;
						letter-spacing: 0.3px;
						margin:0;

						a{
							color: #000000 !important;
						}
					}
					.list_details{
						margin-top:25px;

						@media (max-width: 767px){
							margin-top:15px;
						}


						.item{
							width:100%;
							clear:both;
							margin-bottom: 8px;

							@media (max-width: 767px){
								margin-bottom: 10px;
							}

							&:last-of-type{
								margin-bottom: 0;
							}

							&:after{
								content: '';
								clear:both;
								display: table;
							}

							> div{
								&:after{
									content: '';
									clear:both;
									display: table;
								}
							}

							.start{
								background:url('../img/poczatek.png') no-repeat top 1px left;
							}
							.end{
								background:url('../img/koniec.png') no-repeat top 1px left;
							}
							.roundCalc{
								background:url('../img/route2.png') no-repeat top 2px left;
							}
							.member{
								background:url('../img/house.png') no-repeat top 1px left;
							}
							.title{
								padding-left: 25px;
								font-weight: 700;
								text-transform: uppercase;
								width: 215px;
								float: left;

								@media (max-width: 550px){
									width: 100%;
								}
							}

							.text{
								font-weight: 400;
								text-transform: uppercase;
								color:#717374;
								float:right;
								width: calc(100% - 230px);

								@media (max-width: 550px){
									width: 100%;
									margin-top:5px;
								}
							}
						}
					}
				}
			}

		}
	}
	.rating{
		margin-top: 10px;
		margin-bottom: 20px;
		@media all and (max-width:990px) and (min-width:320px) {
			clear:both;
		}
		&.gold{
			span{
				&.active{
					&:before{
						content: "\f005";
						width:50px;
						height:50px;
						font-size: 22px;
						color: #f7941d;
						margin-right: 4px;
					}
					&:hover{
						 &:before{
							 content: "\f005";
							 width:50px;
							 height:50px;
							 font-size: 22px;
							 color: #f7941d;
							 margin-right: 4px;

						 }
					 }
				}
				&:hover{
					&:before{
						content: "\f006";
						width: 50px;
						height: 50px;
						font-size: 22px;
						color: #717374;
						margin-right: 4px;

					}
				}
			}
		}
		span{
			content: '';
			font-size:50px;
			color:#000;
			font: normal normal normal 14px/1 FontAwesome;
			font-weight:400;
			cursor: pointer;

			&.hover{
				&:before{
					content: "\f005";
					width:50px;
					height:50px;
					font-size: 22px;
					color: #f7941d;
					margin-right: 4px;
				}
			}
			&.active{
				&:before{
					content: "\f005";
					width:50px;
					height:50px;
					font-size: 22px;
					color: #f7941d;
					margin-right: 4px;
				}
			}
			&:before{
				content: "\f006";
				width:50px;
				height:50px;
				font-size: 22px;
				color: #717374;
				margin-right: 4px;
			}
		}

	}

}
section#atrakcjePogranicza{
	margin-top:60px;

	.headline{
		margin:0 0 40px 0;
	}

	.row{
		margin-left:-7.5px;
		margin-right: -7.5px;

		> div{
			padding-left:7.5px;
			padding-right: 7.5px;
		}
	}

	#AttractionsBox{
		margin-bottom: 30px;
	}

	.item{
		height: 240px;
		position:relative;
		margin-bottom: 15px;
		cursor: pointer;

		&:hover{
			.image{
			    transform: scale(1.05);
			}
		}

		.image-wrapper{
			overflow:hidden;

			.image{
				background-size: cover !important;
				background-position: center center !important;
				width: 100%;
				height: 240px;
				background-repeat: no-repeat !important;
			    transition: transform 0.5s ease-in-out;
			}
		}
			

		.title{
			position: absolute;
			bottom: 0;
			left:0;
			margin:0;
			background: rgba(0, 94, 32, 0.71);
			width: 100%;
			color:#ffffff;
			font-size:18px;
			font-weight: bold;
			text-transform: uppercase;
			padding: 15px 15px 13px 15px;
			letter-spacing: 1px;
			min-height: 66px;
			
			@media (max-width: 1199px){
				font-size:16px;
				min-height: 62px;
			}
		}

	}


}

.pagination{
	margin: 35px 0;
	li{
		&.active{
			a{
				background:#246c3b;
				color:#fff;
				border: 1px solid #246c3b;
			}
		}
		a{
			color:#272829;
			border:1px solid #c9c9c9;
			font-family: 'Lato', sans-serif;
			margin-left: 7px;
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
			border-top-right-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
		}
	}
}


#AttractionsBox{
	@media all and (max-width:990px) and (min-width:320px) {
		margin-left: -5px;
		margin-right: -20px;
	}


}

.filters-toggle{
    width: 100%;
    line-height: 46px;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 15px;
    font-size: 14px;
    transition: background .3s ease-in-out,border-color .3s ease-in-out;
    border: 2px solid #898989;
    color: #898989;
    background: none;
    box-shadow: none;
    display: none;
    margin: 0;
    position: relative;
    text-align:left;
    outline: 0;

    @media (max-width: 991px){
    	display: block;
    	margin-bottom: 15px;
    }

    &.active{
    	background:#898989;
    	color:#fff;

    	&:after{
    		transform: translateY(-50%) rotate(180deg);
    	}
    }

	&:after{
	    content: '\f107';
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);
	    font: normal normal normal 14px/1 FontAwesome;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    font-size: 18px;
	}
}

#filters-col{
	@media (max-width: 991px){
		display: none;

		&.active{
			display: block;
		}
	}
}
@media all and (max-width: 767px){
	.hideOnDesktop{
		display:block !important;
	}
	.showOnRwd{
		display:block;
	}
	.hideOnRwd{
		display:none;
	}
	.showOnDesktop{
		display:none !important;
	}
}
@media screen and (max-width: 767px){
	.showOnRwd{
		display:block;
	}
	.hideOnRwd{
		display:none;
	}
	.showOnDesktop{
		display:none !important;
	}
	.table-responsive {
		width: 100%;
		margin-bottom: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: none !important;
	}

}

.hoverMap{
	span.name{
		font-size:15px;
		font-weight: 600;
		color:#000;
	}
	span.city{
		font-size:12px;
		font-weight: 400;
		color:#000;
	}
}