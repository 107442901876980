html{
	overflow-x:hidden;
}
body{
	font-family: 'Signika', sans-serif;
	overflow-x:hidden;
	padding:0 !important;
}
.container-fluid{
	padding-right:0px;
	padding-left:0px;
}

.container {
	@media (min-width: 1520px){
		width: 1520px;
	}
}
.pRelative{
	position: relative;
}
.headline{
	color:#000;
	font-weight: 700;
	font-size:28px;
	margin:40px 0;

	@media (max-width: 767px){
		margin: 25px 0;
	}
}

/*	MENU - HAMBURGER 	*/
.menuHamburger{
	display:none;
	@media (max-width: 1024px) {
		display:block;
	}
	#hamburgerOpens{
		.hamburger-inner,
		.hamburger-inner:after,
		.hamburger-inner:before{
			background-color:#fff;
		}
	}
}


/* SLICK FIX */
#top-slider-wrapper .item{
    display: none;
}
#top-slider-wrapper .item:first-child{
    display: block;
}
#top-slider-wrapper.slick-initialized .slick-slide,
#top-slider-wrapper.slick-initialized .slick-slide:first-child{
    display: block;
}
.slick-slider {
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important; 
	outline: 0 !important;

	*{
		outline: 0;
	}

	a{
		outline: 0;
	}
}
