#header{
	background: rgba(0, 0, 0, 0.68);
	padding: 25px 0;
	position: fixed;
	z-index: 99;
	width: 100%;
	transition: padding 0.15s linear;

	&.sticky{
		padding: 10px 0;
		.languages-wrapper{
			ul{
				li{
					&.toRightSite{
						top: 20px;
					}
				}
			}
		}
	}
	
	@media (max-width: 991px){
		padding:15px 0;
	}

	&:after{
		content: '';
		clear:both;
		display: table;
	}

	.logos-wrapper{
		float:left;
		
		a{
			display: inline-block;
		}
		
		#logo1{
			@media (max-width: 1199px){				
				width: 125px;
				height: auto;
			}

			@media (max-width: 550px){
				width: 87px;
			}
		}

		#logo2{
			margin-left: 20px;
			margin-top:5px;

			@media (max-width: 1199px){				
				width: 144px;
				height: auto;
			}

			@media (max-width: 767px){
				margin-left:10px;
			}

			@media (max-width: 550px){
				width: 100px;
			}
		}
	}

	.hamburger-wrapper{
		float:right;
		display: none;
		margin: 4px -5px 0 15px;

		@media (max-width: 991px){
			display: block;
		}

		@media (max-width: 550px){
			margin-right: 0;
		}

		.hamburger{
			padding:5px;

			@media (max-width: 550px){
				padding:0;
			}
		}
	}

	.menu-wrapper{
		float:right;
		margin-right: 40px;
		margin-top:16px;

		@media (max-width: 1199px){
			margin-right: 0px;
			margin-top:13px;
		}

		@media (max-width: 991px) {
			display:none;
			margin:10px 0 0 0;
			width: 100%;

			&.active{
				display: block;
			}
		}

		ul{
			margin:0;

			li{
				margin-right: 40px;
				display: inline-block;
				&.withSubmenu{
					&:hover{
						@media (min-width: 991px) {
							ul.submenu {
								background: #f1f6fa;
								display: block;
								position: absolute;
								> li {
									border-bottom: 1px solid #000;
									display: block;
									line-height: 120%;
									padding: 10px 10px;
									width: 100%;
									a {
										color: #000;
										font-size: 14px;
									}
								}
							}
						}
					}
					ul.submenu{
						display:none;

						@media (max-width: 991px){
							display:block;
						}
					}

				}

				@media (max-width: 1199px){
					margin-right: 25px;
				}

				@media (max-width: 991px){
					width: 100%;
					margin: 10px 0 0 0;
					text-align: center;
				}

				&:last-of-type{
					margin-right: 0;

				}

				a{
					font-size:16px;
					text-transform: uppercase;
					color:#fff;
					font-weight: 500;

					@media (max-width: 991px){
						padding:5px 0;
						margin:5px 0;
						font-size:18px;
					}
				}
			}
		}
	}

	.languages-wrapper{
		float:right;
		margin-top:13px;

		@media (max-width: 1199px){
			margin-top:10px;
		}

		@media (max-width: 767px){
			&:not(.mobile){
				display: none;
			}
		}

		&.mobile{
			display: none;
			width: 100%;


			@media (max-width: 767px){
				display: block;
				text-align:center;
				margin-top:20px;
			}
		}

		ul{
			margin:0;

			li{
				display: inline-block;
				margin-left: 10px;

				@media (max-width: 767px){
					display: inline-block;
					width: auto;
					margin:0px 5px; 
				}
				
				@media (min-width: 768px){
					&:first-of-type{
						margin-right: 0;
					}
				}
				&.toRightSite{
					position: absolute;
					right: 20px;
					top: 35px;
				}
			}
		}
	}

}
#paginationRoutes{
	margin-top: 30px;
}