#slider{
    position:relative;

    .container{
        position:absolute;
        top:0;
        left:0;
        right: 0;
        height: 450px;
        pointer-events: none;

        @media (max-width: 991px){
            height: 350px;
        }

        @media (max-width: 550px){
            height: 250px;
        }
    }

    #top-slider-wrapper {
        width: 100%;
        height: 450px;

        @media (max-width: 991px){
            height: 350px;
        }

        @media (max-width: 550px){
            height: 250px;
        }

        .item{
            height: 450px;

            @media (max-width: 991px){
                height: 350px;
            }

            @media (max-width: 550px){
                height: 250px;
            }
        }
    }

    #top-slider-arrows-wrapper{
        .slick-arrow.slick-prev{
            background: url('../img/arrow_left.png') no-repeat;
            background-size:contain;
            width: 53px;
            height: 52px;
            border:0;
            outline: 0;
            text-indent:-9999px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top:50%;
            left:15px;
            z-index: 10;
            pointer-events:auto;

            @media (max-width: 991px){
                width: 40px;
                height: 40px;
            }
         
        }
        .slick-arrow.slick-next{
            background: url('../img/arrow_right.png') no-repeat;
            background-size:contain;
            width: 53px;
            height: 52px;
            border:0;
            outline: 0;
            text-indent:-9999px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top:50%;
            right: 15px;
            z-index: 10;
            pointer-events:auto;

            @media (max-width: 991px){
                width: 40px;
                height: 40px;
            }
            
        }
    }
}