.modal-custom{
	padding:0 !important;

	.modal-dialog{
		min-height: calc(100% - 30px);
		width: 100%;
		display: flex;
		align-items: center;
		margin: 15px auto;
		pointer-events: none;
		width: calc(100% - 30px);

		@media (min-width: 992px){
			width: 940px;
		}

		@media (min-width: 1200px){
			width: 1140px;
		}

		@media (min-width: 1520px){
			width: 1490px
		}


	}

	.modal-content-custom{
		background: #fff;
		pointer-events:auto;
	    height: auto;
	    position:relative;
	    // max-height: 100%;

		.row{
			display: flex;

			@media (max-width: 767px){
				display: block;
			}

			> div{
				display: flex;

				@media (max-width: 767px){
					display: block;
				}
			}
		}

		.btn-close{
			position:absolute;
			top:15px;
			right: 15px;
			background: url(../img/close-ico.png) no-repeat;
			background-size:contain;
			width: 30px;
			height: 30px;
			border:0;
			outline: 0;
			padding:0;
			z-index: 55;
		}

		.img-wrapper{
			width: 100%;
			display: flex;
			min-height: 75vh;

			@media (max-width: 1199px){
				min-height: 60vh;
			}

			@media (max-width: 767px){
				min-height: 200px;
			}

			.img{
				width: 100%;
			}
		}

		.text-wrapper{
			padding:60px 30px 60px 0;

			@media (max-width: 991px){
				padding-bottom:30px;
			}

			@media (max-width: 767px){
				padding:15px;
			}

			.title-wrapper{
				margin-bottom:50px;

				@media (max-width: 991px){
					margin-bottom:30px;
				}

				@media (max-width: 767px){
					margin-bottom: 15px;
				}

				.title{
					margin:0;
					color:#000;
					font-size:28px;
					font-weight: bold;
					text-transform: uppercase;
		
				}
			}

			.content-wrapper{
				p{
					font-size:14px;
					color:#717374;
					margin:0 0 30px 0;

					&:last-of-type{
						margin-bottom: 0;
					}

					a{
						color:inherit;
					}

					@media (max-width: 1199px){
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}