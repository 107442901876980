@import url('../../node_modules/reset-css/reset.css');
@import url('../../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('../../node_modules/swiper/dist/css/swiper.min.css');
@import url('../../node_modules/hamburgers/dist/hamburgers.min.css');
@import url('../../node_modules/slick-carousel/slick/slick.css');
@import url('../../node_modules/magnific-popup/dist/magnific-popup.css');
@import url('../../node_modules/animsition/dist/css/animsition.css');

@import 'components/_global.scss';
@import 'components/_header.scss';
@import 'components/_footer.scss';
@import 'components/_slider.scss';
@import 'components/_pagination.scss';
@import 'components/_modal.scss';
@import 'components/_browsers_fix.scss';

@import 'pages/_home.scss';
@import 'pages/_news.scss';
@import 'pages/_article.scss';

