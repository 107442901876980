@import url("../../node_modules/reset-css/reset.css");
@import url("../../node_modules/bootstrap/dist/css/bootstrap.css");
@import url("../../node_modules/swiper/dist/css/swiper.min.css");
@import url("../../node_modules/hamburgers/dist/hamburgers.min.css");
@import url("../../node_modules/slick-carousel/slick/slick.css");
@import url("../../node_modules/magnific-popup/dist/magnific-popup.css");
@import url("../../node_modules/animsition/dist/css/animsition.css");
html {
  overflow-x: hidden;
}

body {
  font-family: 'Signika', sans-serif;
  overflow-x: hidden;
  padding: 0 !important;
}

.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 1520px) {
  .container {
    width: 1520px;
  }
}

.pRelative {
  position: relative;
}

.headline {
  color: #000;
  font-weight: 700;
  font-size: 28px;
  margin: 40px 0;
}

@media (max-width: 767px) {
  .headline {
    margin: 25px 0;
  }
}

/*	MENU - HAMBURGER 	*/
.menuHamburger {
  display: none;
}

@media (max-width: 1024px) {
  .menuHamburger {
    display: block;
  }
}

.menuHamburger #hamburgerOpens .hamburger-inner,
.menuHamburger #hamburgerOpens .hamburger-inner:after,
.menuHamburger #hamburgerOpens .hamburger-inner:before {
  background-color: #fff;
}

/* SLICK FIX */
#top-slider-wrapper .item {
  display: none;
}

#top-slider-wrapper .item:first-child {
  display: block;
}

#top-slider-wrapper.slick-initialized .slick-slide,
#top-slider-wrapper.slick-initialized .slick-slide:first-child {
  display: block;
}

.slick-slider {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
  outline: 0 !important;
}

.slick-slider * {
  outline: 0;
}

.slick-slider a {
  outline: 0;
}

#header {
  background: rgba(0, 0, 0, 0.68);
  padding: 25px 0;
  position: fixed;
  z-index: 99;
  width: 100%;
  transition: padding 0.15s linear;
}

#header.sticky {
  padding: 10px 0;
}

#header.sticky .languages-wrapper ul li.toRightSite {
  top: 20px;
}

@media (max-width: 991px) {
  #header {
    padding: 15px 0;
  }
}

#header:after {
  content: '';
  clear: both;
  display: table;
}

#header .logos-wrapper {
  float: left;
}

#header .logos-wrapper a {
  display: inline-block;
}

@media (max-width: 1199px) {
  #header .logos-wrapper #logo1 {
    width: 125px;
    height: auto;
  }
}

@media (max-width: 550px) {
  #header .logos-wrapper #logo1 {
    width: 87px;
  }
}

#header .logos-wrapper #logo2 {
  margin-left: 20px;
  margin-top: 5px;
}

@media (max-width: 1199px) {
  #header .logos-wrapper #logo2 {
    width: 144px;
    height: auto;
  }
}

@media (max-width: 767px) {
  #header .logos-wrapper #logo2 {
    margin-left: 10px;
  }
}

@media (max-width: 550px) {
  #header .logos-wrapper #logo2 {
    width: 100px;
  }
}

#header .hamburger-wrapper {
  float: right;
  display: none;
  margin: 4px -5px 0 15px;
}

@media (max-width: 991px) {
  #header .hamburger-wrapper {
    display: block;
  }
}

@media (max-width: 550px) {
  #header .hamburger-wrapper {
    margin-right: 0;
  }
}

#header .hamburger-wrapper .hamburger {
  padding: 5px;
}

@media (max-width: 550px) {
  #header .hamburger-wrapper .hamburger {
    padding: 0;
  }
}

#header .menu-wrapper {
  float: right;
  margin-right: 40px;
  margin-top: 16px;
}

@media (max-width: 1199px) {
  #header .menu-wrapper {
    margin-right: 0px;
    margin-top: 13px;
  }
}

@media (max-width: 991px) {
  #header .menu-wrapper {
    display: none;
    margin: 10px 0 0 0;
    width: 100%;
  }
  #header .menu-wrapper.active {
    display: block;
  }
}

#header .menu-wrapper ul {
  margin: 0;
}

#header .menu-wrapper ul li {
  margin-right: 40px;
  display: inline-block;
}

@media (min-width: 991px) {
  #header .menu-wrapper ul li.withSubmenu:hover ul.submenu {
    background: #f1f6fa;
    display: block;
    position: absolute;
  }
  #header .menu-wrapper ul li.withSubmenu:hover ul.submenu > li {
    border-bottom: 1px solid #000;
    display: block;
    line-height: 120%;
    padding: 10px 10px;
    width: 100%;
  }
  #header .menu-wrapper ul li.withSubmenu:hover ul.submenu > li a {
    color: #000;
    font-size: 14px;
  }
}

#header .menu-wrapper ul li.withSubmenu ul.submenu {
  display: none;
}

@media (max-width: 991px) {
  #header .menu-wrapper ul li.withSubmenu ul.submenu {
    display: block;
  }
}

@media (max-width: 1199px) {
  #header .menu-wrapper ul li {
    margin-right: 25px;
  }
}

@media (max-width: 991px) {
  #header .menu-wrapper ul li {
    width: 100%;
    margin: 10px 0 0 0;
    text-align: center;
  }
}

#header .menu-wrapper ul li:last-of-type {
  margin-right: 0;
}

#header .menu-wrapper ul li a {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 991px) {
  #header .menu-wrapper ul li a {
    padding: 5px 0;
    margin: 5px 0;
    font-size: 18px;
  }
}

#header .languages-wrapper {
  float: right;
  margin-top: 13px;
}

@media (max-width: 1199px) {
  #header .languages-wrapper {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  #header .languages-wrapper:not(.mobile) {
    display: none;
  }
}

#header .languages-wrapper.mobile {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  #header .languages-wrapper.mobile {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
}

#header .languages-wrapper ul {
  margin: 0;
}

#header .languages-wrapper ul li {
  display: inline-block;
  margin-left: 10px;
}

@media (max-width: 767px) {
  #header .languages-wrapper ul li {
    display: inline-block;
    width: auto;
    margin: 0px 5px;
  }
}

@media (min-width: 768px) {
  #header .languages-wrapper ul li:first-of-type {
    margin-right: 0;
  }
}

#header .languages-wrapper ul li.toRightSite {
  position: absolute;
  right: 20px;
  top: 35px;
}

#paginationRoutes {
  margin-top: 30px;
}

section#newsletter {
  overflow: hidden;
}

section#newsletter .headline {
  position: relative;
  z-index: 10;
  margin: 0 0 25px 0;
}

section#newsletter .container .newsletter-wrapper {
  position: relative;
  background: #f1f6fa;
  padding: 25px 15px;
  overflow: hidden;
}

section#newsletter .container .newsletter-wrapper:before {
  content: ' ';
  position: absolute;
  top: 36px;
  left: -63px;
  background: url("../img/koperty_newsletter_left.png") no-repeat;
  width: 340px;
  height: 300px;
  background-size: 80%;
  z-index: 5;
}

@media (max-width: 767px) {
  section#newsletter .container .newsletter-wrapper:before {
    background-size: 60%;
  }
}

section#newsletter .container .newsletter-wrapper:after {
  content: ' ';
  position: absolute;
  top: 36px;
  right: -63px;
  background: url("../img/koperty_newsletter_right.png") no-repeat;
  width: 340px;
  height: 300px;
  background-size: 80%;
  z-index: 5;
}

@media (max-width: 767px) {
  section#newsletter .container .newsletter-wrapper:after {
    background-size: 60%;
    right: -250px;
    top: auto;
    bottom: -150px;
  }
}

section#newsletter input.inputEmail {
  height: 63px;
  border: none;
  color: #898989;
  font-size: 14px;
  width: 50%;
  padding: 10px 20px;
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
}

@media (max-width: 991px) {
  section#newsletter input.inputEmail {
    height: 44px;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 767px) {
  section#newsletter input.inputEmail {
    width: 100%;
  }
}

section#newsletter button.submitNewsletter {
  border: 1px solid #898989;
  min-width: 140px;
  width: auto;
  font-size: 14px;
  color: #898989;
  text-align: center;
  text-transform: uppercase;
  padding-top: 11px;
  padding-bottom: 11px;
  background: transparent;
  position: relative;
  z-index: 10;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}

section#newsletter button.submitNewsletter:hover, section#newsletter button.submitNewsletter:focus {
  background: #898989;
  color: #fff;
}

section#footer {
  border-top: 1px solid #cccccc;
  margin-top: 40px;
}

section#footer .realizacja {
  color: #5f6369;
  font-size: 12px;
  font-weight: 300;
}

section#footer .logo-wrapper {
  width: 216px;
  text-align: center;
  display: inline-block;
}

@media (max-width: 991px) {
  section#footer .logo-wrapper {
    width: 194px;
  }
}

@media (max-width: 767px) {
  section#footer .logo-wrapper {
    width: 155px;
  }
}

@media (max-width: 550px) {
  section#footer .logo-wrapper {
    width: 100%;
    margin-top: 10px;
  }
  section#footer .logo-wrapper:first-of-type {
    margin-top: 0;
  }
}

section#footer .logo-wrapper .logo-link {
  display: inline-block;
}

section#footer .logo-wrapper .logo-link img {
  display: inline-block;
  height: auto;
}

@media (max-width: 991px) {
  section#footer .logo-wrapper .logo-link.logo-1 img {
    width: 129px;
  }
}

@media (max-width: 767px) {
  section#footer .logo-wrapper .logo-link.logo-1 img {
    width: 103px;
  }
}

@media (max-width: 550px) {
  section#footer .logo-wrapper .logo-link.logo-1 img {
    width: 129px;
  }
}

@media (max-width: 991px) {
  section#footer .logo-wrapper .logo-link.logo-2 img {
    width: 78px;
  }
}

@media (max-width: 767px) {
  section#footer .logo-wrapper .logo-link.logo-2 img {
    width: 62px;
  }
}

@media (max-width: 550px) {
  section#footer .logo-wrapper .logo-link.logo-2 img {
    width: 78px;
  }
}

@media (max-width: 991px) {
  section#footer .logo-wrapper .logo-link.logo-3 img {
    width: 194px;
  }
}

@media (max-width: 767px) {
  section#footer .logo-wrapper .logo-link.logo-3 img {
    width: 155px;
  }
}

@media (max-width: 550px) {
  section#footer .logo-wrapper .logo-link.logo-3 img {
    width: 194px;
  }
}

section#footer .pt40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  section#footer .pt40 {
    padding: 25px 0;
  }
}

#slider {
  position: relative;
}

#slider .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 450px;
  pointer-events: none;
}

@media (max-width: 991px) {
  #slider .container {
    height: 350px;
  }
}

@media (max-width: 550px) {
  #slider .container {
    height: 250px;
  }
}

#slider #top-slider-wrapper {
  width: 100%;
  height: 450px;
}

@media (max-width: 991px) {
  #slider #top-slider-wrapper {
    height: 350px;
  }
}

@media (max-width: 550px) {
  #slider #top-slider-wrapper {
    height: 250px;
  }
}

#slider #top-slider-wrapper .item {
  height: 450px;
}

@media (max-width: 991px) {
  #slider #top-slider-wrapper .item {
    height: 350px;
  }
}

@media (max-width: 550px) {
  #slider #top-slider-wrapper .item {
    height: 250px;
  }
}

#slider #top-slider-arrows-wrapper .slick-arrow.slick-prev {
  background: url("../img/arrow_left.png") no-repeat;
  background-size: contain;
  width: 53px;
  height: 52px;
  border: 0;
  outline: 0;
  text-indent: -9999px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 15px;
  z-index: 10;
  pointer-events: auto;
}

@media (max-width: 991px) {
  #slider #top-slider-arrows-wrapper .slick-arrow.slick-prev {
    width: 40px;
    height: 40px;
  }
}

#slider #top-slider-arrows-wrapper .slick-arrow.slick-next {
  background: url("../img/arrow_right.png") no-repeat;
  background-size: contain;
  width: 53px;
  height: 52px;
  border: 0;
  outline: 0;
  text-indent: -9999px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  z-index: 10;
  pointer-events: auto;
}

@media (max-width: 991px) {
  #slider #top-slider-arrows-wrapper .slick-arrow.slick-next {
    width: 40px;
    height: 40px;
  }
}

.pagination-wrapper {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 1520px) {
  .pagination-wrapper {
    margin-bottom: 30px;
  }
}

.pagination-wrapper ul {
  margin: 0;
  text-align: center;
  display: inline-block;
}

.pagination-wrapper ul:after {
  content: '';
  clear: both;
  display: table;
}

.pagination-wrapper ul li {
  float: left;
  margin: 0px 3px;
}

.pagination-wrapper ul li a {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #272829;
  border: 1px solid #c9c9c9;
  text-decoration: none;
  transition: all 0.10s linear;
}

.pagination-wrapper ul li a:hover {
  background: #c9c9c9;
}

.pagination-wrapper ul li.active a {
  border-color: #246c3b;
  background: #246c3b;
  color: #fff;
}

body.contrast .pagination-wrapper {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 1520px) {
  body.contrast .pagination-wrapper {
    margin-bottom: 30px;
  }
}

body.contrast .pagination-wrapper ul {
  margin: 0;
  text-align: center;
  display: inline-block;
}

body.contrast .pagination-wrapper ul:after {
  content: '';
  clear: both;
  display: table;
}

body.contrast .pagination-wrapper ul li {
  float: left;
  margin: 0px 3px;
}

body.contrast .pagination-wrapper ul li a {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 29px;
  color: #272829;
  border: 1px solid #c9c9c9;
  text-decoration: none;
  transition: all 0.10s linear;
  background: #fff;
}

body.contrast .pagination-wrapper ul li a:hover {
  background: #c9c9c9;
}

body.contrast .pagination-wrapper ul li.active a {
  border-color: #246c3b;
  background: #246c3b;
  color: #fff;
}

.modal-custom {
  padding: 0 !important;
}

.modal-custom .modal-dialog {
  min-height: calc(100% - 30px);
  width: 100%;
  display: flex;
  align-items: center;
  margin: 15px auto;
  pointer-events: none;
  width: calc(100% - 30px);
}

@media (min-width: 992px) {
  .modal-custom .modal-dialog {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .modal-custom .modal-dialog {
    width: 1140px;
  }
}

@media (min-width: 1520px) {
  .modal-custom .modal-dialog {
    width: 1490px;
  }
}

.modal-custom .modal-content-custom {
  background: #fff;
  pointer-events: auto;
  height: auto;
  position: relative;
}

.modal-custom .modal-content-custom .row {
  display: flex;
}

@media (max-width: 767px) {
  .modal-custom .modal-content-custom .row {
    display: block;
  }
}

.modal-custom .modal-content-custom .row > div {
  display: flex;
}

@media (max-width: 767px) {
  .modal-custom .modal-content-custom .row > div {
    display: block;
  }
}

.modal-custom .modal-content-custom .btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: url(../img/close-ico.png) no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  padding: 0;
  z-index: 55;
}

.modal-custom .modal-content-custom .img-wrapper {
  width: 100%;
  display: flex;
  min-height: 75vh;
}

@media (max-width: 1199px) {
  .modal-custom .modal-content-custom .img-wrapper {
    min-height: 60vh;
  }
}

@media (max-width: 767px) {
  .modal-custom .modal-content-custom .img-wrapper {
    min-height: 200px;
  }
}

.modal-custom .modal-content-custom .img-wrapper .img {
  width: 100%;
}

.modal-custom .modal-content-custom .text-wrapper {
  padding: 60px 30px 60px 0;
}

@media (max-width: 991px) {
  .modal-custom .modal-content-custom .text-wrapper {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .modal-custom .modal-content-custom .text-wrapper {
    padding: 15px;
  }
}

.modal-custom .modal-content-custom .text-wrapper .title-wrapper {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .modal-custom .modal-content-custom .text-wrapper .title-wrapper {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .modal-custom .modal-content-custom .text-wrapper .title-wrapper {
    margin-bottom: 15px;
  }
}

.modal-custom .modal-content-custom .text-wrapper .title-wrapper .title {
  margin: 0;
  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-custom .modal-content-custom .text-wrapper .content-wrapper p {
  font-size: 14px;
  color: #717374;
  margin: 0 0 30px 0;
}

.modal-custom .modal-content-custom .text-wrapper .content-wrapper p:last-of-type {
  margin-bottom: 0;
}

.modal-custom .modal-content-custom .text-wrapper .content-wrapper p a {
  color: inherit;
}

@media (max-width: 1199px) {
  .modal-custom .modal-content-custom .text-wrapper .content-wrapper p {
    margin-bottom: 15px;
  }
}

body.safari .modal-custom .modal-content-custom .img-wrapper .img {
  transform: translateX(-1px);
}

@media (max-width: 767px) {
  body.safari .modal-custom .modal-content-custom .img-wrapper .img {
    transform: none;
  }
}

.showOnRwd {
  display: none;
}

.showOnDesktop {
  display: block;
}

.hideOnRwd {
  display: block;
}

.hideOnDesktop {
  display: none !important;
}

/*	ZMIANA 	*/
.img_poster {
  background: url("../img/bg-app.png") no-repeat;
  width: 100%;
  height: 350px;
  background-size: contain;
  position: relative;
}

.img_poster .link_list {
  position: absolute;
  width: 200px;
  height: 170px;
  bottom: 10px;
  text-align: center;
}

.img_poster .link_list a img {
  height: auto;
  width: 116px;
  margin-bottom: 5px;
}

.ResizeFontPlus,
.ResizeFontMinus,
.changeContrastButton {
  background-color: #f1f6fa;
  border-color: #f1f6fa;
  padding: 2px 5px;
  border-radius: 0px;
  color: #000;
}

.changeContrastButton {
  background: #fff url("../img/contrast-icon.png") no-repeat;
  width: 27px;
  height: 27px;
  background-size: 72%;
  background-position-x: 4px;
  background-position-y: 4px;
}

body {
  background: #fff;
}

body section#downloadAppLinks {
  background: #000;
  padding-top: 10px;
}

body section#downloadAppLinks p {
  color: #fff;
}

body section#downloadAppLinks p span {
  margin-right: 25px;
}

body section#downloadAppLinks p a {
  margin-right: 25px;
}

@media (max-width: 767px) {
  body section#downloadAppLinks p a {
    display: block;
    margin-top: 10px;
  }
}

body section#downloadAppLinks p a img {
  width: 100px;
}

@media (max-width: 767px) {
  body section#downloadAppLinks p a img {
    width: 150px;
  }
}

body.contrast {
  background: #000;
}

body.contrast .headline {
  color: #FFF832;
  font-weight: 700;
  font-size: 28px;
  margin: 40px 0;
}

@media (max-width: 767px) {
  body.contrast .headline {
    margin: 25px 0;
  }
}

body.contrast section#main #cols .leftCols {
  background: #000;
  border: 1px solid #FFF832;
}

body.contrast section#main #cols .leftCols .head {
  color: #FFF832;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0px 0;
}

body.contrast section#main #cols .leftCols .box {
  margin-top: 20px;
  margin-bottom: 25px;
}

body.contrast section#main #cols .leftCols .box:last-of-type {
  margin-bottom: 0;
}

body.contrast section#main #cols .leftCols .box div.checkbox {
  margin-top: 10px;
}

body.contrast section#main #cols .leftCols .box div.checkbox label {
  font-size: 16px;
}

body.contrast section#main #cols .leftCols .box div.checkbox label span {
  color: #FFF832;
  font-weight: 600;
}

body.contrast section#main #cols .leftCols .box div.checkbox label span:before {
  border: 1px solid #FFF832;
}

body.contrast section#main #cols .leftCols .box div.checkbox input[type="checkbox"]:checked + span:after {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 11px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0px;
  top: -2px;
}

body.contrast section#main #cols .leftCols .box span.titleOnStars {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #FFF832;
}

body.contrast section#main #cols .leftCols .box .submitFiltrs {
  border: 2px solid #FFF832;
  min-width: 100%;
  font-size: 16px;
  color: #FFF832;
  font-weight: 700;
}

body.contrast section#main #cols #routeList .route {
  border: 1px solid #FFF832;
}

body.contrast section#main #cols #routeList .route .more {
  display: none !important;
}

body.contrast section#main #cols #routeList .route .rightText {
  background: #000;
}

body.contrast section#main #cols #routeList .route .rightText h5 {
  color: #FFF832;
}

body.contrast section#main #cols #routeList .route .rightText h5 a {
  color: #FFF832 !important;
}

body.contrast section#main #cols #routeList .route .rightText .list_details .item .title {
  font-size: 17px;
  color: #FFF832;
}

body.contrast section#main #cols #routeList .route .rightText .list_details .item .text {
  font-weight: 600;
  font-size: 16px;
  color: #FFF832;
}

body.contrast section#main.offer #cols .rightCols#offerCenter h2 {
  color: #FFF832;
}

body.contrast section#main.offer #cols .rightCols#offerCenter h3 {
  color: #FFF832;
  font-size: 25px;
}

body.contrast section#main.offer #cols .rightCols#offerCenter p.desc {
  color: #FFF832;
  font-weight: 400;
  font-size: 20px;
}

body.contrast section#main.offer #cols .rightCols#offerCenter .list_details .item .title {
  font-size: 16px;
  color: #FFF832;
}

body.contrast section#main.offer #cols .rightCols#offerCenter .list_details .item .text {
  font-size: 16px;
  color: #FFF832;
}

body.contrast section#main.offer #cols .rightCols#offerCenter .listAttributes .item {
  color: #FFF832;
  font-size: 15px;
}

body.contrast section#main.offer #cols .rightCols#offerCenter ul.listText li {
  font-size: 15px;
  color: #FFF832;
}

body.contrast section#newsletter .headline {
  color: #000 !important;
}

body.contrast section#newsletter button.submitNewsletter {
  border: 2px solid #000;
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

body.contrast section#newsletter input.inputEmail {
  color: #000;
  font-size: 19px;
  font-weight: 600;
}

body.contrast section#footer .realizacja {
  color: #FFF832;
  font-size: 16px;
  font-weight: 400;
}

section#main {
  position: relative;
  overflow: hidden;
}

section#main.offer {
  margin-top: 40px;
}

section#main.offer #cols .leftCols.offer a.back {
  color: #005e20;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  content: '';
  position: relative;
}

section#main.offer #cols .leftCols.offer a.back:before {
  content: ' ';
  background: url("../img/next.png") no-repeat;
  position: absolute;
  width: 25px;
  height: 21px;
}

section#main.offer #cols .leftCols.offer a.back span {
  padding-left: 40px;
}

section#main.offer #cols .leftCols.offer .img {
  margin-top: 30px;
  margin-bottom: 30px;
}

section#main.offer #cols .leftCols.offer h5 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

section#main.offer #cols .rightCols {
  width: 100%;
}

section#main.offer #cols .rightCols#offerCenter #map {
  height: 500px;
  width: 100%;
  border: 1px solid #a2a3a3;
}

section#main.offer #cols .rightCols#offerCenter .list_details {
  margin-top: 40px;
}

section#main.offer #cols .rightCols#offerCenter .list_details .item {
  width: 100%;
  clear: both;
  margin-bottom: 8px;
}

section#main.offer #cols .rightCols#offerCenter .list_details .item .title {
  padding-left: 0px;
  font-weight: 600;
  text-transform: uppercase;
  width: 250px;
  float: left;
  font-size: 14px;
}

@media all and (max-width: 990px) and (min-width: 320px) {
  section#main.offer #cols .rightCols#offerCenter .list_details .item .title {
    width: 100%;
  }
}

section#main.offer #cols .rightCols#offerCenter .list_details .item .text {
  font-weight: 400;
  text-transform: uppercase;
  color: #717374;
  border: none;
}

section#main.offer #cols .rightCols#offerCenter .listAttributes {
  margin-top: 25px;
  color: #000000;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

section#main.offer #cols .rightCols#offerCenter .listAttributes .item {
  margin-bottom: 35px;
}

section#main.offer #cols .rightCols#offerCenter .listAttributes .item img {
  margin-right: 30px;
  width: 25px;
}

section#main.offer #cols .rightCols#offerCenter h2 {
  font-size: 26px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

section#main.offer #cols .rightCols#offerCenter h3 {
  font-size: 22px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Signika', sans-serif;
}

section#main.offer #cols .rightCols#offerCenter h3.noclegi {
  content: '';
  padding-left: 40px;
  position: relative;
  padding-top: 4px;
  font-size: 19px;
  font-weight: 500;
}

section#main.offer #cols .rightCols#offerCenter h3.noclegi:before {
  content: ' ';
  background: url("../img/placeholder_yelow.png") no-repeat;
  width: 23px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

section#main.offer #cols .rightCols#offerCenter h3.atrakcje {
  content: '';
  color: #80cbc4;
  padding-left: 40px;
  position: relative;
  padding-top: 4px;
  font-size: 19px;
  font-weight: 500;
}

section#main.offer #cols .rightCols#offerCenter h3.atrakcje:before {
  content: ' ';
  background: url("../img/placeholder_blue.png") no-repeat;
  width: 23px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

section#main.offer #cols .rightCols#offerCenter h3.gastronomia {
  content: '';
  color: #e57373;
  padding-left: 40px;
  position: relative;
  padding-top: 4px;
  font-size: 19px;
  font-weight: 500;
}

section#main.offer #cols .rightCols#offerCenter h3.gastronomia:before {
  content: ' ';
  background: url("../img/placeholder_red.png") no-repeat;
  width: 26px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

section#main.offer #cols .rightCols#offerCenter h3.parkingi {
  content: '';
  color: #9575cd;
  padding-left: 40px;
  position: relative;
  padding-top: 4px;
  font-size: 19px;
  font-weight: 500;
}

section#main.offer #cols .rightCols#offerCenter h3.parkingi:before {
  content: ' ';
  background: url("../img/placeholder_pur.png") no-repeat;
  width: 23px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

section#main.offer #cols .rightCols#offerCenter h3.inne {
  content: '';
  color: #7cb342;
  padding-left: 40px;
  position: relative;
  padding-top: 4px;
  font-size: 19px;
  font-weight: 500;
}

section#main.offer #cols .rightCols#offerCenter h3.inne:before {
  content: ' ';
  background: url("../img/placeholder_green.png") no-repeat;
  width: 23px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

section#main.offer #cols .rightCols#offerCenter ul.listText {
  color: #717374;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 35px;
}

section#main.offer #cols .rightCols#offerCenter ul.listText li {
  margin-bottom: 10px;
}

section#main.offer #cols .rightCols#offerCenter .icons {
  margin-top: 20px;
  margin-bottom: 15px;
}

section#main.offer #cols .rightCols#offerCenter .icons img {
  float: left;
  margin-right: 12px;
  width: 25px;
}

section#main.offer #cols .rightCols#offerCenter p.desc {
  margin-top: 20px;
  color: #717374;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

section#main.offer #cols .rightCols#offerCenter .gallery .item {
  width: calc(20% - 10px);
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

@media all and (max-width: 990px) and (min-width: 320px) {
  section#main.offer #cols .rightCols#offerCenter .gallery .item {
    width: calc(50% - 10px);
  }
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku {
  border: 1px solid #dfdfe0;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku thead {
  background: #f1f6fa;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku thead tr th {
  font-size: 11px;
  font-weight: 600;
  color: #717374;
  border-right: 1px solid #dfdfe0;
  text-align: center;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku thead tr th:last-child {
  border-right: none;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku tbody tr td {
  border-right: 1px solid #dfdfe0;
  border-bottom: 1px solid #dfdfe0;
  background: #ffffff;
  font-size: 11px;
  font-weight: 400;
  color: #717374;
  text-align: center;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku tbody tr td:last-child {
  border-right: none;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku tbody tr td span.no {
  width: 16px;
  height: 16px;
  background: url("../img/no_icon.png") no-repeat;
  display: block;
  margin: 0 auto;
}

section#main.offer #cols .rightCols#offerCenter table.odcinkiSzlaku tbody tr td span.yes {
  width: 16px;
  height: 16px;
  background: url("../img/yes_icon.png") no-repeat;
  display: block;
  margin: 0 auto;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi {
  border: 1px solid #dfdfe0;
  margin: 20px 0px;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi thead {
  background: #ffd600;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi thead tr th {
  font-size: 10px;
  font-weight: 600;
  color: #717374;
  border-right: 1px solid #dfdfe0;
  text-align: center;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi thead tr th:last-child {
  border-right: none;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi tbody tr td {
  border-right: 1px solid #dfdfe0;
  border-bottom: 1px solid #dfdfe0;
  background: #ffffff;
  font-size: 11px;
  font-weight: 400;
  color: #717374;
  text-align: center;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi tbody tr td:last-child {
  border-right: none;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi tbody tr td span.no {
  width: 16px;
  height: 16px;
  background: url("../img/no_icon.png") no-repeat;
  display: block;
  margin: 0 auto;
}

section#main.offer #cols .rightCols#offerCenter table.noclegi tbody tr td span.yes {
  width: 16px;
  height: 16px;
  background: url("../img/yes_icon.png") no-repeat;
  display: block;
  margin: 0 auto;
}

section#main:before {
  content: ' ';
  background: url("../img/bg_left.png") no-repeat;
  background-size: 80%;
  width: 174px;
  height: 2157px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

@media all and (max-width: 990px) and (min-width: 320px) {
  section#main:before {
    display: none;
  }
}

section#main:after {
  content: ' ';
  background: url("../img/bg_right.png") no-repeat;
  background-size: 81%;
  width: 224px;
  height: 2157px;
  position: absolute;
  top: 0;
  right: 0;
  background-position: 50px 0px;
  pointer-events: none;
  z-index: -1;
}

section#main #cols {
  display: flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

@media (max-width: 1024px) {
  section#main #cols {
    display: block;
  }
}

section#main #cols > div {
  padding-left: 7.5px;
  padding-right: 7.5px;
  display: flex;
}

@media (max-width: 1024px) {
  section#main #cols > div {
    display: block;
  }
}

section#main #cols .leftCols {
  background: #f1f6fa;
  padding: 20px 15px;
  width: 100%;
}

section#main #cols .leftCols .head {
  color: #005e20;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0px 0;
}

section#main #cols .leftCols .box {
  margin-top: 20px;
  margin-bottom: 25px;
}

section#main #cols .leftCols .box:last-of-type {
  margin-bottom: 0;
}

section#main #cols .leftCols .box div.checkbox {
  margin-top: 10px;
}

section#main #cols .leftCols .box div.checkbox label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0;
  position: relative;
  min-height: auto;
}

section#main #cols .leftCols .box div.checkbox label input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

section#main #cols .leftCols .box div.checkbox label span {
  padding-left: 22px;
}

section#main #cols .leftCols .box div.checkbox label span:before {
  content: '';
  width: 12px;
  height: 12px;
  border: 1px solid #717374;
  background: none;
  position: absolute;
  top: 1px;
  left: 0;
}

section#main #cols .leftCols .box div.checkbox label input[type="checkbox"]:checked + span:after {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 11px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 1px;
  top: 2px;
}

section#main #cols .leftCols .box span.titleOnStars {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
}

section#main #cols .leftCols .box .submitFiltrs {
  border: 1px solid #898989;
  min-width: 140px;
  width: auto;
  font-size: 14px;
  color: #898989;
  text-align: center;
  text-transform: uppercase;
  padding-top: 11px;
  padding-bottom: 11px;
  background: transparent;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}

section#main #cols .leftCols .box .submitFiltrs:hover, section#main #cols .leftCols .box .submitFiltrs:focus {
  background: #898989;
  color: #fff;
}

section#main #cols .rightCols {
  width: 100%;
}

section#main #cols #routeList .route {
  margin-bottom: 15px;
  display: flex;
  position: relative;
}

@media all and (max-width: 767px) {
  section#main #cols #routeList .route {
    display: block;
  }
}

section#main #cols #routeList .route .more {
  position: absolute;
  background: url("../img/blue-arrow.png") no-repeat;
  width: 11px;
  height: 23px;
  top: calc(50% - 11.5px);
  right: 20px;
}

@media (max-width: 1199px) {
  section#main #cols #routeList .route .more {
    right: 10px;
  }
}

section#main #cols #routeList .route:last-child {
  margin-bottom: 0px;
}

section#main #cols #routeList .route .leftImg {
  width: 255px;
  display: flex;
  overflow: hidden;
}

@media all and (max-width: 767px) {
  section#main #cols #routeList .route .leftImg {
    width: 100%;
    height: 150px;
  }
}

section#main #cols #routeList .route .leftImg a {
  background-size: cover !important;
  background-position: center center !important;
  cursor: pointer;
  display: block;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

section#main #cols #routeList .route .leftImg a:hover {
  transform: scale(1.05);
}

section#main #cols #routeList .route .rightText {
  padding: 20px 40px 20px 20px;
  background: #f1f6fa;
  width: calc(100% - 255px);
  position: relative;
}

@media (max-width: 991px) {
  section#main #cols #routeList .route .rightText {
    padding-right: 30px;
  }
}

@media all and (max-width: 767px) {
  section#main #cols #routeList .route .rightText {
    width: 100%;
    padding: 15px 30px 15px 15px;
  }
}

section#main #cols #routeList .route .rightText h5 {
  color: #000000;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin: 0;
}

section#main #cols #routeList .route .rightText h5 a {
  color: #000000 !important;
}

section#main #cols #routeList .route .rightText .list_details {
  margin-top: 25px;
}

@media (max-width: 767px) {
  section#main #cols #routeList .route .rightText .list_details {
    margin-top: 15px;
  }
}

section#main #cols #routeList .route .rightText .list_details .item {
  width: 100%;
  clear: both;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  section#main #cols #routeList .route .rightText .list_details .item {
    margin-bottom: 10px;
  }
}

section#main #cols #routeList .route .rightText .list_details .item:last-of-type {
  margin-bottom: 0;
}

section#main #cols #routeList .route .rightText .list_details .item:after {
  content: '';
  clear: both;
  display: table;
}

section#main #cols #routeList .route .rightText .list_details .item > div:after {
  content: '';
  clear: both;
  display: table;
}

section#main #cols #routeList .route .rightText .list_details .item .start {
  background: url("../img/poczatek.png") no-repeat top 1px left;
}

section#main #cols #routeList .route .rightText .list_details .item .end {
  background: url("../img/koniec.png") no-repeat top 1px left;
}

section#main #cols #routeList .route .rightText .list_details .item .roundCalc {
  background: url("../img/route2.png") no-repeat top 2px left;
}

section#main #cols #routeList .route .rightText .list_details .item .member {
  background: url("../img/house.png") no-repeat top 1px left;
}

section#main #cols #routeList .route .rightText .list_details .item .title {
  padding-left: 25px;
  font-weight: 700;
  text-transform: uppercase;
  width: 215px;
  float: left;
}

@media (max-width: 550px) {
  section#main #cols #routeList .route .rightText .list_details .item .title {
    width: 100%;
  }
}

section#main #cols #routeList .route .rightText .list_details .item .text {
  font-weight: 400;
  text-transform: uppercase;
  color: #717374;
  float: right;
  width: calc(100% - 230px);
}

@media (max-width: 550px) {
  section#main #cols #routeList .route .rightText .list_details .item .text {
    width: 100%;
    margin-top: 5px;
  }
}

section#main .rating {
  margin-top: 10px;
  margin-bottom: 20px;
}

@media all and (max-width: 990px) and (min-width: 320px) {
  section#main .rating {
    clear: both;
  }
}

section#main .rating.gold span.active:before {
  content: "\f005";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #f7941d;
  margin-right: 4px;
}

section#main .rating.gold span.active:hover:before {
  content: "\f005";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #f7941d;
  margin-right: 4px;
}

section#main .rating.gold span:hover:before {
  content: "\f006";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #717374;
  margin-right: 4px;
}

section#main .rating span {
  content: '';
  font-size: 50px;
  color: #000;
  font: normal normal normal 14px/1 FontAwesome;
  font-weight: 400;
  cursor: pointer;
}

section#main .rating span.hover:before {
  content: "\f005";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #f7941d;
  margin-right: 4px;
}

section#main .rating span.active:before {
  content: "\f005";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #f7941d;
  margin-right: 4px;
}

section#main .rating span:before {
  content: "\f006";
  width: 50px;
  height: 50px;
  font-size: 22px;
  color: #717374;
  margin-right: 4px;
}

section#atrakcjePogranicza {
  margin-top: 60px;
}

section#atrakcjePogranicza .headline {
  margin: 0 0 40px 0;
}

section#atrakcjePogranicza .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

section#atrakcjePogranicza .row > div {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

section#atrakcjePogranicza #AttractionsBox {
  margin-bottom: 30px;
}

section#atrakcjePogranicza .item {
  height: 240px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

section#atrakcjePogranicza .item:hover .image {
  transform: scale(1.05);
}

section#atrakcjePogranicza .item .image-wrapper {
  overflow: hidden;
}

section#atrakcjePogranicza .item .image-wrapper .image {
  background-size: cover !important;
  background-position: center center !important;
  width: 100%;
  height: 240px;
  background-repeat: no-repeat !important;
  transition: transform 0.5s ease-in-out;
}

section#atrakcjePogranicza .item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  background: rgba(0, 94, 32, 0.71);
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 15px 13px 15px;
  letter-spacing: 1px;
  min-height: 66px;
}

@media (max-width: 1199px) {
  section#atrakcjePogranicza .item .title {
    font-size: 16px;
    min-height: 62px;
  }
}

.pagination {
  margin: 35px 0;
}

.pagination li.active a {
  background: #246c3b;
  color: #fff;
  border: 1px solid #246c3b;
}

.pagination li a {
  color: #272829;
  border: 1px solid #c9c9c9;
  font-family: 'Lato', sans-serif;
  margin-left: 7px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@media all and (max-width: 990px) and (min-width: 320px) {
  #AttractionsBox {
    margin-left: -5px;
    margin-right: -20px;
  }
}

.filters-toggle {
  width: 100%;
  line-height: 46px;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 15px;
  font-size: 14px;
  transition: background .3s ease-in-out,border-color .3s ease-in-out;
  border: 2px solid #898989;
  color: #898989;
  background: none;
  box-shadow: none;
  display: none;
  margin: 0;
  position: relative;
  text-align: left;
  outline: 0;
}

@media (max-width: 991px) {
  .filters-toggle {
    display: block;
    margin-bottom: 15px;
  }
}

.filters-toggle.active {
  background: #898989;
  color: #fff;
}

.filters-toggle.active:after {
  transform: translateY(-50%) rotate(180deg);
}

.filters-toggle:after {
  content: '\f107';
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

@media (max-width: 991px) {
  #filters-col {
    display: none;
  }
  #filters-col.active {
    display: block;
  }
}

@media all and (max-width: 767px) {
  .hideOnDesktop {
    display: block !important;
  }
  .showOnRwd {
    display: block;
  }
  .hideOnRwd {
    display: none;
  }
  .showOnDesktop {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .showOnRwd {
    display: block;
  }
  .hideOnRwd {
    display: none;
  }
  .showOnDesktop {
    display: none !important;
  }
  .table-responsive {
    width: 100%;
    margin-bottom: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: none !important;
  }
}

.hoverMap span.name {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.hoverMap span.city {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

#news-page .title-wrapper {
  text-align: center;
  margin: 40px 0;
}

#news-page .title-wrapper .title {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

#news-page .news-wrapper .row {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1520px) {
  #news-page .news-wrapper .row {
    margin: 0px -30px;
  }
}

#news-page .news-wrapper .row:before, #news-page .news-wrapper .row:after {
  width: 0;
}

#news-page .news-wrapper .row > div {
  display: flex;
}

@media (min-width: 1520px) {
  #news-page .news-wrapper .row > div {
    padding: 0px 30px;
  }
}

#news-page .news-wrapper .article {
  background: #f1f6fa;
  margin-bottom: 60px;
  width: 100%;
}

@media (max-width: 1519px) {
  #news-page .news-wrapper .article {
    margin-bottom: 30px;
  }
}

#news-page .news-wrapper .article .img-wrapper {
  overflow: hidden;
}

#news-page .news-wrapper .article .img-wrapper:hover .img {
  transform: scale(1.05);
}

#news-page .news-wrapper .article .img-wrapper .img {
  height: 245px;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  display: block;
}

@media (max-width: 1199px) {
  #news-page .news-wrapper .article .img-wrapper .img {
    height: 190px;
  }
}

#news-page .news-wrapper .article .text-wrapper {
  padding: 20px;
}

@media (max-width: 1199px) {
  #news-page .news-wrapper .article .text-wrapper {
    padding: 15px;
  }
}

#news-page .news-wrapper .article .text-wrapper .date {
  display: block;
  font-size: 16px;
  color: #717374;
}

#news-page .news-wrapper .article .text-wrapper .title-wrapper {
  margin: 0px 0 5px 0;
  text-align: left;
}

#news-page .news-wrapper .article .text-wrapper .title-wrapper .title {
  text-decoration: none;
  color: #717374;
  display: inline-block;
}

#news-page .news-wrapper .article .text-wrapper .title-wrapper .title h3 {
  display: inline-block;
  margin: 0;
  color: #717374;
  font-size: 22px;
  font-weight: bold;
}

#news-page .news-wrapper .article .text-wrapper .description-wrapper {
  margin-bottom: 15px;
}

#news-page .news-wrapper .article .text-wrapper .description-wrapper p {
  font-size: 14px;
  color: #717374;
  margin: 0;
}

#news-page .news-wrapper .article .text-wrapper .more-wrapper a {
  text-transform: uppercase;
  color: #005e20;
  font-size: 16px;
  font-weight: bold;
}

body.contrast #article-page .title-wrapper .title {
  color: #FFF832;
}

body.contrast #article-page .article-wrapper p {
  font-size: 16px;
  color: #FFF832;
}

#article-page {
  padding-bottom: 60px;
}

@media (max-width: 1520px) {
  #article-page {
    padding-bottom: 30px;
  }
}

#article-page .title-wrapper {
  text-align: center;
  margin: 40px 0 20px 0;
}

#article-page .title-wrapper .title {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

#article-page .article-wrapper p {
  font-size: 14px;
  color: #717374;
  margin: 0 0 30px 0;
}

#article-page .article-wrapper p a {
  color: inherit;
}

@media (max-width: 1199px) {
  #article-page .article-wrapper p {
    margin-bottom: 15px;
  }
}

#article-page .article-wrapper img {
  margin: 20px 0;
  max-width: 100%;
  height: auto;
}
