
body{
	&.contrast{
		#article-page{
			.title-wrapper{
				.title{
					color:#FFF832;
				}
			}

			.article-wrapper{
				p{
					font-size: 16px;
					color: #FFF832;
				}
			}
		}

	}
}
#article-page{
	padding-bottom:60px;
	
	@media (max-width: 1520px){
		padding-bottom:30px;
	}

	.title-wrapper{
		text-align: center;
		margin: 40px 0 20px 0;
		
		.title{
			color:#000;
			font-size:28px;
			font-weight: bold;
			text-transform: uppercase;
			margin:0;
		}
	}

	.article-wrapper{
		p{
			font-size:14px;
			color:#717374;
			margin:0 0 30px 0;

			a{
				color:inherit;
			}

			@media (max-width: 1199px){
				margin-bottom: 15px;
			}
		}

		img{
			margin:20px 0;
			max-width: 100%;
			height: auto;
		}
	}
}