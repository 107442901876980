.pagination-wrapper{
	text-align:center;
	margin-bottom: 60px;

	@media (max-width:1520px){
		margin-bottom: 30px;	
	}

	ul{
		margin:0;
		text-align: center;
		display: inline-block;

		&:after{
			content: '';
			clear: both;
			display: table;
		}

		li{
			float:left;
			margin:0px 3px;

			a{
				display: flex;
				width: 30px;
				height: 30px;
				justify-content: center;
				align-items: center;
				font-size:11px;
				color:#272829;
				border:1px solid #c9c9c9;
				text-decoration: none;
				transition: all 0.10s linear;

				&:hover{
					background: #c9c9c9;
				}
			}

			&.active{
				a{
					border-color:#246c3b;
					background:#246c3b;
					color:#fff;
				}
			}
		}
	}
}

body{
	&.contrast{
		.pagination-wrapper{
			text-align:center;
			margin-bottom: 60px;

			@media (max-width:1520px){
				margin-bottom: 30px;
			}

			ul{
				margin:0;
				text-align: center;
				display: inline-block;

				&:after{
					content: '';
					clear: both;
					display: table;
				}

				li{
					float:left;
					margin:0px 3px;

					a{
						display: flex;
						width: 40px;
						height: 40px;
						justify-content: center;
						align-items: center;
						font-size: 29px;
						color:#272829;
						border:1px solid #c9c9c9;
						text-decoration: none;
						transition: all 0.10s linear;
						background: #fff;

						&:hover{
							background: #c9c9c9;
						}
					}

					&.active{
						a{
							border-color:#246c3b;
							background:#246c3b;
							color:#fff;
						}
					}
				}
			}
		}
	}
}