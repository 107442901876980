#news-page{
	
	.title-wrapper{
		text-align: center;
		margin: 40px 0;
		
		.title{
			color:#000;
			font-size:28px;
			font-weight: bold;
			text-transform: uppercase;
			margin:0;
		}
	}

	.news-wrapper{
		.row{
			display: flex;
			flex-wrap: wrap;

			@media (min-width: 1520px){
				margin: 0px -30px;
			}

			&:before,
			&:after{
				width: 0;
			}

			> div{
				display: flex;

				@media (min-width: 1520px){
					padding:0px 30px;
				}
			}
		}
		

		.article{
			background: #f1f6fa;
			margin-bottom: 60px;
			width: 100%;

			@media (max-width: 1519px){
				margin-bottom: 30px;
			}

			.img-wrapper{
				overflow:hidden;

				&:hover{
					.img{
						transform:scale(1.05);
					}
				}

				.img{
					height: 245px;
					width: 100%;
					transition: transform 0.5s ease-in-out;
					display: block;

					@media (max-width: 1199px){
						height: 190px;
					}
				}
			}

			.text-wrapper{
				padding:20px; 

				@media (max-width: 1199px){
					padding:15px;
				}

				.date{
					display: block;
					font-size:16px;
					color:#717374
				}

				.title-wrapper{
					margin:0px 0 5px 0;
					text-align: left;

					.title{
						text-decoration: none;
						color:#717374;
						display: inline-block;

						h3{
							display: inline-block;
							margin:0;
							color:#717374;
							font-size:22px;
							font-weight: bold;
						}
					}
				}

				.description-wrapper{
					margin-bottom: 15px;

					p{
						font-size:14px;
						color:#717374;
						margin:0;
					}
				}

				.more-wrapper{
					a{
						text-transform: uppercase;
						color:#005e20;
						font-size:16px;
						font-weight: bold;
					}
				}
			}
		}
	}
}