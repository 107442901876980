section#newsletter{
	overflow:hidden;

	.headline{
		position:relative;
		z-index: 10;
		margin:0 0 25px 0;
	}


	.container{
		

		.newsletter-wrapper{
			position: relative;
			background:#f1f6fa;
			padding:25px 15px;
			overflow: hidden;
			
			&:before{
				content: ' ';
				position: absolute;
				top: 36px;
				left: -63px;
				background: url('../img/koperty_newsletter_left.png')no-repeat;
				width:340px;
				height:300px;
				background-size: 80%;
				z-index: 5;

				@media (max-width: 767px){
					background-size:60%;
				}
			}

			&:after{
				content: ' ';
				position: absolute;
				top: 36px;
				right: -63px;
				background: url('../img/koperty_newsletter_right.png')no-repeat;
				width:340px;
				height:300px;
				background-size: 80%;
				z-index: 5;

				@media (max-width: 767px){
					background-size:60%;
					right: -250px;
					top:auto;
					bottom:-150px;
				}
			}
		}
		
		
	}

	input.inputEmail{
		height: 63px;
		border: none;
		color: #898989;
		font-size: 14px;
		width: 50%;
		padding: 10px 20px;
		margin-bottom: 25px;
		position:relative;
		z-index: 10;

		@media (max-width: 991px){
			height: 44px;
			margin:0 0 15px 0;

		}

		@media (max-width: 767px){
			width: 100%;
		}
	}

	button.submitNewsletter{
		border: 1px solid #898989;
		min-width: 140px;
		width: auto;
		font-size: 14px;
		color: #898989;
		text-align: center;
		text-transform: uppercase;
		padding-top: 11px;
		padding-bottom: 11px;
		background: transparent;
		position:relative;
		z-index: 10;
		transition: background 0.15s ease-in-out, color 0.15s ease-in-out;

		&:hover,
		&:focus{
			background:#898989;
			color:	#fff;
		}
	}
}

section#footer{
	border-top:1px solid #cccccc;
  	margin-top: 40px;
				

	.realizacja{
		color:#5f6369;
		font-size:12px;
		font-weight: 300;
	}

	.logo-wrapper{
		width: 216px;
		text-align: center;
		display: inline-block;

		@media (max-width: 991px){
			width: 194px;
		}

		@media (max-width: 767px){
			width: 155px;
		}

		@media (max-width: 550px){
			width: 100%;
			margin-top:10px;

			&:first-of-type{
				margin-top:0;
			}
		}

		.logo-link{
			display: inline-block;

			img{
				display: inline-block;	
				height: auto;
			}

			&.logo-1{
				img{
					@media (max-width: 991px){
						width: 129px
					}

					@media (max-width: 767px){
						width: 103px;
					}

					@media (max-width: 550px){
						width: 129px
					}
				}
			}

			&.logo-2{
				img{
					@media (max-width: 991px){
						width: 78px
					}

					@media (max-width: 767px){
						width: 62px;
					}

					@media (max-width: 550px){
						width: 78px
					}
				}
			}

			&.logo-3{
				img{
					@media (max-width: 991px){
						width: 194px;
					}

					@media (max-width: 767px){
						width: 155px;
					}

					@media (max-width: 550px){
						width: 194px;
					}
				}
			}
		}
	}


	.pt40{
		padding-top:40px;
		padding-bottom:40px;

		@media (max-width: 767px){
			padding:25px 0;
		}
	}
}